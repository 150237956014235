@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* 
body {
    background-color: blue;
} */
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    transition: .3s ease;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

.section {
    padding: 4rem 0 2rem;
}

.container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon {
    font-size: 2rem;
    cursor: pointer;
}

.flex {
    display: flex;
    align-items: center;
}

.btn {
    padding: .4rem 1.5rem;
    background: var(--backColor);
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
    color: var(--WhiteColor);
}

.btn a {
    color: white;
    font-weight: 500;
}

.btn:hover a {
    color: var(--BlackColor);
}

.secIntro {
    max-width: 70%;
    text-align: center;
    margin: 0 auto 2rem;

}

.secIntro .secTitle {
    color: black;
    line-height: 1.5rem;
    padding-bottom: .5rem;
}

.secIntro p {
    font-size: 13px;
    color: var(--TextColor);
    font-weight: 500;
}

img,
video {
    width: 100%;
    height: auto;
}

input {
    font-size: 100%;
}

:root {
    --PrimaryColor: #010848;
    /* Tu color primario */
    --backColor: #010848;
    --HoverColor: #F2FF49;
    /* Un tono más oscuro */
    --WhiteColor: #ffffff;
    /* Blanco */
    --BlackColor: #000000;
    /* Negro */
    --TextColor: hsl(206, 6%, 45%);
    /* Un tono más oscuro para el texto */
    --WhiteColorDim: hsl(0, 0%, 93%);
    /* Blanco ligeramente atenuado */
    --GreyBg: hsl(206, 10%, 94%);
    /* Un gris claro para el fondo */
    --GreyText: hsl(206, 3%, 75%);
    /* Un gris más oscuro para el texto secundario */
    --InputColor: hsl(206, 15%, 97%);
    /* Un blanco ligeramente azulado para campos de entrada */
}

/* media queries */
@media screen and (min-width: 556px) {
    .mainContent {
        grid-template-columns: repeat(2, 1fr);
        display: grid;
        margin: .5rem;
    }
}