.profile-card-container {
  position: absolute;
  top: 60px;
  /* Ajusta esto según sea necesario */
  right: 10px;
  /* Ajusta esto según sea necesario */
  z-index: 1000;
}

.profile-card {
  border-radius: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  max-width: 324px;
  flex-direction: column;
}

.profile-header {
  border-radius: 24px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.46);
  background-color: #fff;
  display: flex;
  gap: 12px;
  color: var(--Colors-Primary, #010848);
  font-weight: 600;
  text-align: center;
  padding: 20px;
}

.profile-avatar {
  background-color: var(--Colors-Primary, #010848);
  border-radius: 50%;
  align-self: start;
  margin-top: 6px;
  width: 69px;
  height: 69px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-title {
  font: 400 16px Poppins, sans-serif;
}

.profile-greeting {
  font: 24px Poppins, sans-serif;
}

.profile-link {
  display: flex;
  margin-top: 4px;
  padding-right: 5px;
  gap: 5px;
  font-size: 10px;
}

.profile-link-text {
  font-family: Poppins, sans-serif;
  cursor: pointer;
}

.profile-link-img {
  aspect-ratio: 0.55;
  object-fit: auto;
  object-position: center;
  width: 6px;
  fill: var(--Colors-Primary-3, #010848);
  margin: auto 0;
}

.profile-menu {
  display: flex;
  margin-top: 12px;
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  padding: 0 20px 20px;
}

.menu-item {
  cursor: pointer;
  color: var(--TextColor);
  font-size: .9rem;
  font-weight: 600;

  &:hover {
    color: var(--PrimaryColor);
  }
}

.menu-item:not(:first-child) {
  margin-top: 12px;
}

.logout {
  color: var(--Colors-Primary, #010848);
  font-weight: 700;
}