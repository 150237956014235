$PrimaryColor: #010848; /* Tu color primario */
$HoverColor: #F2FF49; /* Un tono más oscuro */
$WhiteColor: #ffffff; /* Blanco */
$BlackColor: #000000; /* Negro */
$TextColor: hsl(206, 6%, 45%); /* Un tono más oscuro para el texto */
$WhiteColorDim: hsl(0, 0%, 93%); /* Blanco ligeramente atenuado */
$GreyBg: hsl(206, 10%, 94%); /* Un gris claro para el fondo */
$GreyText:#37474F; /* Un gris más oscuro para el texto secundario */
$InputColor: hsl(206, 15%, 97%); /* Un blanco ligeramente azulado para campos de entrada */

.footer {
  background-color: $WhiteColor;
  padding: 50px 0;
  text-align: center;

  .container {
    max-width: 80%;
    margin: 0 auto;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

.footer-column {
  flex: 1; 
  width: 100%; /* Asegura que ocupe todo el ancho en dispositivos móviles */
  margin-bottom: 20px; /* Espacio entre columnas */
}

.footerLogo{
    padding-bottom: .5rem;    
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    img{
        width: 220px; /* Tamaño deseado */
        height: auto; /* Mantener proporciones */
        margin-right: 10px; /* Ajustar margen derecho según sea necesario */
    }
}


  .footerLinks {
    
    a {
 
      color:  #37474F;
    }
  }

  .socials {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    a {
      margin-right: 10px;
      color: $PrimaryColor;
    }
  }

  .icon {
    font-size: 25px;
  }

  @media screen and (max-width: 768px) {
    .footer-content {
      flex-direction: column; /* Cambia la dirección a columna en dispositivos móviles */
    }
  
    .footer-column {
      width: 100%; /* Ocupa todo el ancho en dispositivos móviles */
      margin-bottom: 35px; /* Ajusta el margen inferior */
    }
  
    .socials {
      margin-bottom: 20px; /* Ajusta el margen inferior */
    }
  }
  
}