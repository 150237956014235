/* styles.css */
:root {
    --PrimaryColor: #010848; /* Tu color primario */
    --backColor: #010848;
    --HoverColor: #F2FF49; /* Un tono más oscuro */
    --WhiteColor: #ffffff; /* Blanco */
    --BlackColor: #000000; /* Negro */
    --TextColor: hsl(206, 6%, 45%); /* Un tono más oscuro para el texto */
    --WhiteColorDim: hsl(0, 0%, 93%); /* Blanco ligeramente atenuado */
    --GreyBg: hsl(206, 10%, 94%); /* Un gris claro para el fondo */
    --GreyText: hsl(206, 3%, 75%); /* Un gris más oscuro para el texto secundario */
    --InputColor: hsl(206, 15%, 97%); /* Un blanco ligeramente azulado para campos de entrada */
}

.navBarSection{
    .header{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--backColor);
        width: 100%;
        padding: .5rem;
        z-index: 1000;
        .logoDiv {
            cursor: pointer;
            align-items: center;
            justify-content: center;
            display: flex;
            img{
                width: 200px; /* Tamaño deseado */
                height: auto; /* Mantener proporciones */
                margin-right: 10px; /* Ajustar margen derecho según sea necesario */
            }
        }
        .toggleNavBar{
            font-size: 45px;
            color: var(--WhiteColor);
            &:hover{
                color: var(--WhiteColor);
            }
            cursor: pointer;
        }
        @media  screen and (max-width: 1012px) {
            .navBar{
                background: var(--GreyBg);
                position: absolute;
                height: max-content;
                padding: 1rem;
                width: 100%;
                top: 0;
                left: -500%;
                // box-shadow: 0 2px 4px rgba(209, 209, 209, 0.904);
                transition: .5s ease;
                z-index: 1000;

                .navList{
                    flex-direction: column;
                    justify-content: center;
                    max-width: 100%;
                    // margin: auto;

                    .navItem{
                        padding: 1rem 1rem 1rem 1rem;
                        .navLink {
                            color: var(--TextColor);
                            font-size: .9rem;
                            font-weight: 600;
                            &:hover{
                                color: var(--PrimaryColor);
                            }
                        }
                    }

                    .headerBtns{
                        flex-direction: column;
                        .btn{
                            
    
                            a{
                                color: var(--PrimaryColor);
                                font-weight: 500;
                            }
                        }
                        .btnS{
                            
    
                            a{
                                color: var(--WhiteColor);
                                font-weight: 500;
                            }
                            .userName{
                                padding: 1rem;
                            }
                        }
                        .btnS{
                            padding: .4rem 1.5rem;
                            background: var(--WhiteColor);
                            border: none;
                            outline: none;
                            border-radius: 3rem;
                            cursor: pointer;
                            color: var(--WhiteColor);
                        }
                        .btnS {
                            .userName{
                                padding: 2px;
                            }
                        }
                        .btnS  a {
                            color: white;
                            font-weight: 500;
                        }
                        .loginBtn{
                            background: var(--WhiteColor);
                            border: var(--PrimaryColor);
                            color: var(--WhiteColor);
                            margin-bottom: 1rem;
                            a{
                                color: var(--PrimaryColor);
                            }
                            &:hover{
                                background: var(--WhiteColor);
                                a{
                                    color: var(--backColor);
                                }
                            }
    
                        }
                        .signUp{
                            color: var(--WhiteColor)!important;
                            background: #010848;
                            &:hover{
                                background: var(--WhiteColor);
                                a{
                                    color: var(--PrimaryColor);
                                }
                            }
                            .userName{
                                padding-left: 5px;
                                padding-right: 5px;
                            }
                        }
                    }

                }
                .closeNavBar{
                    position: absolute;
                    top: 1rem;
                    right: 1.5rem;
                    color: var(--PrimaryColor);
                    &:hover .icon{
                        color:var(--PrimaryColor)
                    }
                }
            }
            .activeNavBar{
                left: 0;
              
            }
        }
    }
    .activeHeader {
        background-color: var(--backColor); /* Fondo blanco con 20% de opacidad */
        backdrop-filter: blur(10px); /* Aplica un desenfoque de 10 píxeles */
        // box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334); 

    }

}
@media screen and (min-width: 1012px) {
    .navBarSection{
        .toggleNavBar, .closeNavBar{
            display: none;
        }
        .header{
            padding: 1rem 2rem .5rem 2rem;
            .navBar{
                .navList{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: row;
                    .navItem{
                        .navLink{
                            color: var(--WhiteColor);
                            padding: 0 .45rem;
                            font-size: .9rem ;
                            font-weight: 400;
                            &:hover{
                                color: var(--WhiteColor);
                            }
                        }
                    }
                    .btn{
                        margin-left: 1rem;

                        a{
                            color: var(--WhiteColor);
                            font-weight: 500;
                        }
                    }
                    .btnS{
                        margin-left: 1rem;

                        a{
                            color: var(--WhiteColor);
                            font-weight: 500;
                        }
                        .userName{
                            padding: 1rem;
                        }
                    }
                    .btnS{
                        padding: .4rem 1.5rem;
                        background: var(--WhiteColor);
                        border: none;
                        outline: none;
                        border-radius: 3rem;
                        cursor: pointer;
                        color: var(--WhiteColor);
                    }
                    .btnS {
                        .userName{
                            padding: 2px;
                        }
                    }
                    .btnS  a {
                        color: white;
                        font-weight: 500;
                    }
                    .loginBtn{
                        background: none;
                        border: var(--WhiteColor);
                        color: var(--WhiteColor);
                        a{
                            color: var(--WhiteColor);
                        }
                        &:hover{
                            background: var(--WhiteColor);
                            a{
                                color: var(--backColor);
                            }
                        }

                    }
                    .signUp{
                        color: var(--backColor)!important;
                        &:hover{
                            background: var(--WhiteColor);
                            a{
                                color: var(--WhiteColor);
                            }
                        }
                        .userName{
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
.btnS.signUp {
    display: flex;
    align-items: center;
  }
  
  .btnS.signUp .username {
    margin: 0 10px; /* Ajusta el margen derecho e izquierdo según sea necesario */
  }
  
  .btnS.signUp span {
    margin: 0 5px; /* Ajusta el margen derecho e izquierdo de los íconos según sea necesario */
  }
  
  .btnS.signUp span:first-child {
    margin-left: 0; /* Asegura que no haya margen izquierdo en el primer ícono */
  }
  
  .btnS.signUp span:last-child {
    margin-right: 0; /* Asegura que no haya margen derecho en el último ícono */
  }
  
/* @media screen and (min-width: 960px){
    .header{
        .navBar{
            .navList{
                .navItem{
                    // padding: 0 .7rem;
                }
            }
        }
    }
}

@media screen and (min-width: 960px){
    .header{
        padding: 1rem 8.5rem;
    }
} */